import React from 'react';
import { useState, useEffect } from 'react';
import {
    Table,
    IconButton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
} from "@material-ui/core";
import {
    EditOutlined,
    Clear,
    InsertDriveFileOutlined,
    AddCircleOutline, DeleteOutline,
} from "@material-ui/icons";

import {
    changeDocument, createDocument,
    deleteDocument,
    getAllDocuments

} from "./api";
import {I_DOCUMENT, I_PROPS_CREATE_DOCUMENT} from "../../../interfaces/management/DocumentsInterfaces";
import {DOMAIN_URL} from "../../../Config";


export default function DocumentsComponent() {
    const [documents, setDocuments] = useState<Array<I_DOCUMENT>>([]);
    const [currentChangeDocument, setCurrentChangeDocument] = useState<I_DOCUMENT | null>(null);
    const [newDocument, setNewDocument] = useState<I_PROPS_CREATE_DOCUMENT>({name: '', document: null});

    useEffect(() => {
        getAllDocuments()
            .then(res => {
                setDocuments(res);
            });
    }, []);

    const handleCreateDocument = () => {
        createDocument(newDocument)
            .then(res => {
                setDocuments([res, ...documents]);
                setNewDocument({name: '', document: null});
            });
    };

    const handleChangeDocument = () => {
        if (currentChangeDocument) {
            changeDocument(currentChangeDocument.id, currentChangeDocument.name)
                .then(res => {
                    let copyDocuments: Array<I_DOCUMENT> = documents.slice();
                    let indexInDocuments: number = copyDocuments.findIndex(document => document.id === res.id);
                    if (indexInDocuments !== -1) {
                        copyDocuments[indexInDocuments] = res;
                    }
                    setDocuments(copyDocuments);
                    setCurrentChangeDocument(null);
                });
        }
    };

    const handleDeleteDocument = (idDocument: number) => {
        if (window.confirm("Вы точно хотите удалить документ?")) {
            deleteDocument(idDocument)
                .then(res => {
                    setDocuments(documents.filter(document => document.id !== idDocument));
                });
        }
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Документ</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(event) => {
                                        if (event.target.files) {
                                            newDocument.document = event.target.files[0];
                                            setNewDocument({...newDocument});
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField
                                    className="text_fields_full"
                                    label="название документа"
                                    variant="outlined"
                                    value={newDocument.name}
                                    onChange={(event) => {newDocument.name = event.target.value; setNewDocument({...newDocument});}}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCreateDocument}
                                >
                                    <AddCircleOutline />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        {documents.map(document => (
                            <TableRow key={document.id}>
                                <TableCell component="th" scope="row">
                                    <a href={`${DOMAIN_URL}/${document.uri}`} target="_blank">
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                        >
                                            <InsertDriveFileOutlined />
                                        </IconButton>
                                    </a>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangeDocument && currentChangeDocument.id === document.id ?
                                        <TextField
                                            className="text_fields_full"
                                            label="новое имя"
                                            variant="outlined"
                                            value={currentChangeDocument.name}
                                            onChange={(event) => {currentChangeDocument.name = event.target.value; setCurrentChangeDocument({...currentChangeDocument});}}
                                        />
                                        :
                                        <p>
                                            {document.name}
                                        </p>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            if (currentChangeDocument) {
                                                handleChangeDocument();
                                            } else {
                                                setCurrentChangeDocument(document);
                                            }
                                        }}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    {currentChangeDocument && currentChangeDocument.id === document.id ?
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                setCurrentChangeDocument(null);
                                            }}
                                        >
                                            <Clear/>
                                        </IconButton>
                                        :
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                handleDeleteDocument(document.id);
                                            }}
                                        >
                                            <DeleteOutline/>
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}