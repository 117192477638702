import React from 'react';
import { useState } from 'react';
import {
    Card,
    TextField,
    IconButton,
} from "@material-ui/core";
import {
    AddCircleOutline,
    DeleteOutline,
    EditOutlined,
    ClearOutlined,
} from "@material-ui/icons";

import "../../../styles/management/SchedulesComponent.scss";
import {
    I_PROPS_SCHEDULE_MODAL
} from "../../../interfaces/management/SchedulesInterfaces";

export default function ScheduleModalEdit(props: I_PROPS_SCHEDULE_MODAL) {
    const [name, setName] = useState<string>(props.schedule ? props.schedule.name : "");
    const [monday, setMonday] = useState<string>(props.schedule ? props.schedule.monday : "");
    const [tuesday, setTuesday] = useState<string>(props.schedule ? props.schedule.tuesday : "");
    const [wednesday, setWednesday] = useState<string>(props.schedule ? props.schedule.wednesday : "");
    const [thursday, setThursday] = useState<string>(props.schedule ? props.schedule.thursday : "");
    const [friday, setFriday] = useState<string>(props.schedule ? props.schedule.friday : "");
    const [saturday, setSaturday] = useState<string>(props.schedule ? props.schedule.saturday : "");
    const [sunday, setSunday] = useState<string>(props.schedule ? props.schedule.sunday : "");
    const [holiday, setHoliday] = useState<string>(props.schedule ? props.schedule.holiday : "");
    const [particular, setParticular] = useState<string>(props.schedule ? props.schedule.particular : "");

    return (
        <Card className="schedule_modal_edit">
            <IconButton
                edge="start"
                color="inherit"
                className="close_button"
                onClick={props.handleCloseModal}
            >
                <ClearOutlined />
            </IconButton>
            {props.schedule ?
                <h4>
                    Редактирование расписания
                </h4>
                :
                <h4>
                    Создание расписания
                </h4>
            }
            <TextField
                label="Название рсписания:"
                variant="outlined"
                value={name}
                className="field"
                onChange={(event) => {setName(event.target.value)}}
            />
            <TextField
                label="Понедельник:"
                variant="outlined"
                value={monday}
                className="field"
                onChange={(event) => {setMonday(event.target.value)}}
            />
            <TextField
                label="Вторник:"
                variant="outlined"
                value={tuesday}
                className="field"
                onChange={(event) => {setTuesday(event.target.value)}}
            />
            <TextField
                label="Среда:"
                variant="outlined"
                value={wednesday}
                className="field"
                onChange={(event) => {setWednesday(event.target.value)}}
            />
            <TextField
                label="Четверг:"
                variant="outlined"
                value={thursday}
                className="field"
                onChange={(event) => {setThursday(event.target.value)}}
            />
            <TextField
                label="Пятница:"
                variant="outlined"
                value={friday}
                className="field"
                onChange={(event) => {setFriday(event.target.value)}}
            />
            <TextField
                label="Суббота:"
                variant="outlined"
                value={saturday}
                className="field"
                onChange={(event) => {setSaturday(event.target.value)}}
            />
            <TextField
                label="Воскресенье:"
                variant="outlined"
                value={sunday}
                className="field"
                onChange={(event) => {setSunday(event.target.value)}}
            />
            <TextField
                label="Выходные:"
                variant="outlined"
                value={holiday}
                className="field"
                onChange={(event) => {setHoliday(event.target.value)}}
            />
            <TextField
                label="Другое:"
                variant="outlined"
                value={particular}
                className="field"
                onChange={(event) => {setParticular(event.target.value)}}
            />
            <div>
                {props.schedule ?
                    <div>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                if (props.schedule) {
                                    props.handleChange({
                                        id: props.schedule.id,
                                        name: name,
                                        monday: monday,
                                        tuesday: tuesday,
                                        wednesday: wednesday,
                                        thursday: thursday,
                                        friday: friday,
                                        saturday: saturday,
                                        sunday: sunday,
                                        holiday: holiday,
                                        particular: particular
                                    });
                                }
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                if (props.schedule) {
                                    props.handleDelete(props.schedule.id);
                                }
                            }}
                        >
                            <DeleteOutline />
                        </IconButton>
                    </div>
                    :
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            props.handleCreate({
                                name: name,
                                monday: monday,
                                tuesday: tuesday,
                                wednesday: wednesday,
                                thursday: thursday,
                                friday: friday,
                                saturday: saturday,
                                sunday: sunday,
                                holiday: holiday,
                                particular: particular
                            });
                        }}
                    >
                        <AddCircleOutline />
                    </IconButton>
                }
            </div>
        </Card>
    );
}