import React from 'react';
import { useState, useEffect } from 'react';
import {
    Card,
    Modal,
} from "@material-ui/core";
import {
    AddCircleOutline,
} from "@material-ui/icons";

import {
    getAllItems,
    createItem,
    changeItem,
    deleteItem,
} from "./api";
import {
    I_PROPS_CHANGE_OR_CREATE_ITEM,
    I_PROPS_PROMISE_ALL_GET_ITEMS,
    I_RESPONSE_PROMISE_ALL_GET_ITEMS
} from "../../../interfaces/management/ItemsInterfaces";
import {Promise} from "q";
import {getAllCategoriesItem} from "../categories/api";
import {I_CATEGORY_ITEM_DTO} from "../../../interfaces/management/CategoriesItemInterfaces";
import ProductCard from "../../products/ProductCard";
import {
    I_ITEM
} from "../../../interfaces/ProductInterfaces";
import ItemModalEdit from "./ItemModalEdit";
import BaseModalComponent from "../../BaseModalComponent";

export default function ItemsComponent() {
    const [items, setItems] = useState<Array<I_ITEM>>([]);
    const [categoriesItems, setCategoriesItems] = useState<Array<I_CATEGORY_ITEM_DTO>>([]);
    const [selectedItem, setSelectedItem] = useState<I_ITEM | null>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    useEffect(() => {
        //@ts-ignore
        Promise.all<I_PROPS_PROMISE_ALL_GET_ITEMS>([
            getAllCategoriesItem(),
            getAllItems()
        ])
            .then((responces: I_RESPONSE_PROMISE_ALL_GET_ITEMS) => {
                setCategoriesItems(responces[0]);
                setItems(responces[1]);
            });
    }, []);

    const handleCreateItem = (inItem: I_PROPS_CHANGE_OR_CREATE_ITEM) => {
        createItem(inItem)
            .then(res => {
                setItems([res, ...items]);
                setIsOpenModal(false);
            });
    };

    const handleChangeItem = (inItem: I_PROPS_CHANGE_OR_CREATE_ITEM) => {
        changeItem(inItem)
            .then(res => {
                let copyItems: Array<I_ITEM> = items.slice();
                let indexInItems: number = copyItems.findIndex(item => item.item.id === res.item.id);
                if (indexInItems !== -1) {
                    copyItems[indexInItems] = res;
                }
                setItems(copyItems);
                setIsOpenModal(false);
            });
    };

    const handleDeleteItem = (inId: number) => {
        if (window.confirm("Вы точно хотите удалить товар?")) {
            deleteItem(inId)
                .then(res => {
                    setItems(items.filter(item => item.item.id !== inId));
                    setIsOpenModal(false);
                });
        }
    };

    return (
        <div className="schedules_page_main_container">
            <Modal
                open={isOpenModal}
                onClose={() => {setIsOpenModal(false)}}
                children={
                    <BaseModalComponent
                        handleCloseModal={() => {setIsOpenModal(false);}}
                        component={
                            <ItemModalEdit
                                item={selectedItem}
                                categories={categoriesItems}
                                handleCloseModal={() => {setIsOpenModal(false)}}
                                handleCreate={handleCreateItem}
                                handleChange={handleChangeItem}
                                handleDelete={handleDeleteItem}
                            />
                        }/>
                }
            />
            <Card
                className="product_card"
                onClick={() => {
                    setSelectedItem(null);
                    setIsOpenModal(true);
                }}
            >
                <AddCircleOutline className="icon_add"/>
            </Card>
            {items.map((item: I_ITEM) => (
                <ProductCard
                    key={`ITEMS_KEY_PRODUCT_CARD_ID${item.item.id}`}
                    item={item}
                    handleOpenModalAboutProduct={(inItem: I_ITEM) => {setSelectedItem(inItem); setIsOpenModal(true);}}
                />
            ))}
        </div>
    );
}