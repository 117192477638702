import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {I_DOCUMENT, I_PROPS_CREATE_DOCUMENT} from "../../../interfaces/management/DocumentsInterfaces";

export function getAllDocuments(): Promise<I_DOCUMENT[]>
{
    return new Promise<I_DOCUMENT[]>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/documents/documents`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function createDocument(props: I_PROPS_CREATE_DOCUMENT): Promise<I_DOCUMENT>
{
    let preparedBodyForRequest = new FormData();
    preparedBodyForRequest.append('name', props.name);
    preparedBodyForRequest.append('document', props.document);
    return new Promise<I_DOCUMENT>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/documents/documents`,
            bodyIsNotJSON: true,
            data: preparedBodyForRequest,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changeDocument(documentID: number, name: string): Promise<I_DOCUMENT>
{
    return new Promise<I_DOCUMENT>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/documents/documents/${documentID}`,
            data: {name},
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function deleteDocument(documentID: number): Promise<number>
{
    return new Promise<number>((resolve, reject) => {
        sendHTTPRequest({
            method: "DELETE",
            url: `${DOMAIN_API_URL}/api/management/documents/documents/${documentID}`,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}