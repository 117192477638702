import {Card} from "@material-ui/core";
import React from "react";

import "../../styles/products/ProductCard.scss";
import {I_CARD_PRODUCT} from "../../interfaces/ProductInterfaces";
import {DOMAIN_API_URL} from "../../Config";

export default function ProductCard(props: I_CARD_PRODUCT)
{
    const {
        item,
        handleOpenModalAboutProduct,
    } = props;
    return (
        <Card
            className="product_card"
            onClick={() => {handleOpenModalAboutProduct(item)}}
        >
            <div className="product_card_image_container">
                <img
                    src={`${DOMAIN_API_URL}/${item.item.miniature_picture}`}
                    alt="img"
                />
            </div>
            <div className="product_info_container">
                <span className="head">
                    { item.item.name }
                </span>
                {item.item.manufacturer.length > 1 &&
                    <span>
                        Производитель:<br/><b>{item.item.manufacturer}</b>
                    </span>
                }
                {item.item.composition.length > 1 &&
                    <span>
                        Состав:<br/><b>{ item.item.composition }</b>
                    </span>
                }
                {item.item.product_unit.length > 1 &&
                    <span>
                        Измерение:<br/><b>{item.item.product_unit}</b>
                    </span>
                }
            </div>
        </Card>
    );
}
