import React from "react";
import {Route, Switch} from "react-router-dom";
import ProductsComponent from "./products/ProductsComponent";
import TopBar from "./TopBar";
import "../styles/MainView.scss";
import BottomBar from "./BottomBar";
import About from "./About";
import Contacts from "./Contacts";

export default function MainView() {
    return (
        <div className="main_view_main_container">
            <TopBar />
            <Switch>
                <Route exact path='/' children={<ProductsComponent />}/>
                <Route exact path='/about' children={<About />}/>
                <Route exact path='/contacts' children={<Contacts />}/>
            </Switch>
            <BottomBar />
        </div>
    );
}