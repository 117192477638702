import React, {ReactElement, useState} from "react";
import {observer} from "mobx-react";
import {Card, IconButton, Modal} from "@material-ui/core";

import ProductsStore from "../../store/ProductsStore";
import {I_CATEGORY, I_ITEM, I_POINT_OF_SALE, I_PRODUCT, I_SCHEDULE} from "../../interfaces/ProductInterfaces";
import "../../styles/products/ModalAboutProduct.scss";
import {DOMAIN_API_URL} from "../../Config";
import {MapOutlined} from "@material-ui/icons";
import BaseModalComponent from "../BaseModalComponent";
import {getWeekDay, PropsWeekDay} from "../../services/Utils";

type TypeProps = {
    item: I_ITEM;
};
type TypeState = {
    modalContent: ReactElement | null
};

class ModalAboutProduct extends React.Component<TypeProps, TypeState>{

    constructor(props: TypeProps) {
        super(props);

        this.state = {
            modalContent: null
        }
    }

    private handlePreviewMap(integratedMap: string)
    {
        this.setState({
            modalContent: (<div dangerouslySetInnerHTML={{__html:integratedMap}} />)
        });
    }

    render()
    {
        const {
            modalContent
        } = this.state;

        const {
            item
        } = this.props;

        const {
            products,
            pointsOfSale,
            schedules,
            categories,
        } = ProductsStore;

        const currentWeekDay: PropsWeekDay = getWeekDay();
        const itemProducts = products[item.item.id];

        return (
            <div className="container_modal_about_product">
                <Modal
                    open={!!modalContent}
                    onClose={() => {this.setState({modalContent: null})}}
                    children={
                        <BaseModalComponent
                            handleCloseModal={() => {
                                this.setState({modalContent: null});
                            }}
                            component={
                                <div>
                                    {modalContent}
                                </div>
                            }
                        />
                    }
                />
                <div className="container_about_product">
                    <div className="image_product">
                        <img
                            src={`${DOMAIN_API_URL}/${item.item.picture}`}
                            alt="img"
                        />
                    </div>
                    <div className="description_product">
                        <span className="head">
                            { item.item.name }
                        </span>
                        {(item.item.manufacturer && item.item.manufacturer.length > 2) &&
                            <span>
                                Производитель:
                                <br/>
                                <b>
                                    {item.item.manufacturer}
                                </b>
                            </span>
                        }
                        {(item.item.composition && item.item.composition.length > 2) &&
                            <span>
                                Состав:
                                <br/>
                                <b>
                                    { item.item.composition }
                                </b>
                            </span>
                        }
                        {(item.item.product_unit && item.item.product_unit.length > 2) &&
                            <span>
                                Измерение:
                                <br/>
                                <b>
                                    {item.item.product_unit}
                                </b>
                            </span>
                        }
                        {(item.item.description && item.item.description.length > 2) &&
                            <span>
                                Описание:
                                <br/>
                                <b>
                                    {item.item.description}
                                </b>
                            </span>
                        }
                        <span>
                            Категории товара:
                        </span>
                        <div className="categories_product_container">
                            {item.categories.map((idCategory: number) => {
                                const category: I_CATEGORY | undefined = categories.find((currentCategory: I_CATEGORY) => currentCategory.id === idCategory);
                                if (!category) {
                                    return null;
                                }
                                return (
                                    <span
                                        className="product_category_container"
                                        key={`MODAL_ABOUT_ITEM_CATEGORY_${category.id}`}
                                    >
                                        {category.name}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {itemProducts &&
                    <div className="container_points_of_sale">
                        {itemProducts.map((product: I_PRODUCT) => {
                            const pointOfSale: I_POINT_OF_SALE | undefined = pointsOfSale.find(findPOS => findPOS.id === product.id_pos);
                            const schedule: I_SCHEDULE | undefined = pointOfSale ? schedules.find(findS => findS.id === pointOfSale.id_s) : undefined;

                            if (!pointOfSale) {
                                return null;
                            }

                            return (
                                <Card
                                    key={`MODAL_ABOUT_ITEM_PRODUCT_${product.id}`}
                                    className="container_product_in_point_of_sale"
                                >
                                    {pointOfSale.map_integration &&
                                        <IconButton
                                            className="container_preview_map"
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                this.handlePreviewMap(pointOfSale.map_integration);
                                            }}
                                        >
                                            <MapOutlined/>
                                        </IconButton>
                                    }
                                    {product.count > 0 ?
                                        <span>
                                            Количество:
                                            <b>
                                                { product.count }
                                            </b>
                                        </span>
                                        :
                                        <span style={{fontSize: "14px"}}><b>Наличие уточняйте по телефону</b></span>
                                    }
                                    {product.price > 0 &&
                                        <span>
                                            Цена:
                                            <b>
                                                {`${product.price}₽`}
                                            </b>
                                        </span>
                                    }
                                    <span>
                                        Адрес:
                                        <b>
                                            { pointOfSale.address }
                                        </b>
                                    </span>
                                    <span>
                                        Телефон:
                                        <a href={`tel:+${ pointOfSale.phone }`}>
                                            <b>
                                                +{ pointOfSale.phone }
                                            </b>
                                        </a>
                                    </span>
                                    {schedule &&
                                        <span>
                                            {schedule[currentWeekDay.day]}
                                        </span>
                                    }
                                </Card>
                            );
                        })}
                    </div>
                }
            </div>
        );
    }
}

export default observer(ModalAboutProduct);