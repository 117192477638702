import React from "react";
import {observer} from "mobx-react";
import {
    Button, Card,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Modal,
    Select, Switch
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

import ProductsStore from "../../store/ProductsStore";
import MetricsStore from "../../store/MetricsStore";
import "../../styles/products/Products.scss";
import ProductCard from "./ProductCard";
import {I_ITEM} from "../../interfaces/ProductInterfaces";
import ModalAboutProduct from "./ModalAboutProduct";

type TypeProps = {};
type TypeState = {
    isOpenModalAboutProduct: boolean;
    selectedItemForModalProduct: null | I_ITEM;
    selectedIdPOS: null | number;
    categoriesIds: Array<number>;
    isOpenMenuAddCategoriesForFilter: boolean;
    isOnlySelectedCategories: boolean;
    currentPage: number;
    countProductsOnPage: number;
};

class ProductsComponent extends React.Component<TypeProps, TypeState>
{
    constructor(props: TypeProps) {
        super(props);

        this.state = {
            isOpenModalAboutProduct: false,
            selectedItemForModalProduct: null,
            selectedIdPOS: null,
            isOpenMenuAddCategoriesForFilter: false,
            categoriesIds: [],
            isOnlySelectedCategories: false,
            currentPage: 1,
            countProductsOnPage: 30,
        };
    }

    componentDidMount() {
        if (!ProductsStore.isLoaded) {
            ProductsStore.updateStore();
        }
    }

    handleOpenModalAboutProduct(inItem: I_ITEM) {
        MetricsStore.actionOnTheSite("product_card.open_modal_about_product");
        this.setState({
            selectedItemForModalProduct: inItem,
            isOpenModalAboutProduct: true
        });
    }

    renderProductsWithPagination(keysProducts: Array<string>, countKeysProducts: number) {
        const {
            currentPage,
            countProductsOnPage,
        } = this.state;
        const {
            items,
        } = ProductsStore;

        const startValue = (currentPage-1) * countProductsOnPage;
        const expectedLastIndex = (startValue + countProductsOnPage);
        const lastValue = expectedLastIndex < countKeysProducts ? expectedLastIndex : countKeysProducts;

        let result = [];
        for(let i = startValue; i < lastValue; i++) {
            const idItem = keysProducts[i];
            let numberIdItem = parseInt(idItem);
            let item = items.find(item => item.item.id === numberIdItem);
            if (item) {
                result.push(
                    <ProductCard
                        key={`KEY_CARD_PRODUCT_ITEM_${item.item.id}`}
                        item={item}
                        handleOpenModalAboutProduct={(inItem: I_ITEM) => {
                            this.handleOpenModalAboutProduct(inItem)
                        }}
                    />
                );
            }
        }
        return result;
    }

    render() {
        const {
            isOpenModalAboutProduct,
            selectedItemForModalProduct,
            selectedIdPOS,
            categoriesIds,
            isOpenMenuAddCategoriesForFilter,
            isOnlySelectedCategories,
            currentPage,
            countProductsOnPage,
        } = this.state;

        const {
            items,
            pointsOfSale,
            categories,
        } = ProductsStore;

        const products = ProductsStore.productsWithFilter(selectedIdPOS, isOnlySelectedCategories, categoriesIds);
        const keysProducts = Object.keys(products);
        const countKeysProducts = keysProducts.length;
        const countPaginationPages = Math.ceil(countKeysProducts / countProductsOnPage);

        return (
            <div className="container_main_products">
                <Modal
                    open={isOpenModalAboutProduct}
                    onClose={() => {this.setState({isOpenModalAboutProduct: false})}}
                >
                    <div>
                        {selectedItemForModalProduct &&
                            <ModalAboutProduct
                                item={selectedItemForModalProduct}
                            />
                        }
                    </div>
                </Modal>
                <Card className="container_options_sampling">
                    <h4>Фильтры</h4>
                    <FormControl style={{width: "90%"}}>
                        <InputLabel id={`ID_SELECT_LABEL_PRODUCTS_POS`}>Точка продажи</InputLabel>
                        <Select
                            labelId={`ID_SELECT_LABEL_PRODUCTS_POS`}
                            id={`ID_SELECT_SELECT_PRODUCTS_POS`}
                            value={selectedIdPOS ? `${selectedIdPOS}` : undefined}
                            onChange={(event) => {
                                MetricsStore.actionOnTheSite("product_filter.select_point_of_sale");
                                let value = event.target.value;
                                if (typeof value === "number") {
                                    this.setState({selectedIdPOS: value});
                                } else {
                                    this.setState({selectedIdPOS: null});
                                }
                            }}
                        >
                            <MenuItem value="">сбросить выделение</MenuItem>
                            {pointsOfSale.map(pos => (
                                <MenuItem
                                    key={`KEY_SELECT_PRODUCTS_POS_ITEM_${pos.id}`}
                                    value={pos.id}
                                >
                                    {pos.address}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{width: '100%', marginTop: '10px'}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isOnlySelectedCategories}
                                    onChange={() => {
                                        MetricsStore.actionOnTheSite("product_filter.change_rules_categories");
                                        this.setState({isOnlySelectedCategories: !isOnlySelectedCategories});
                                    }}
                                    name="onlySelectedCategories"
                                    color="primary"
                                />
                            }
                            label="Все выбранные категории"
                        />
                        <div style={{margin: "10px 0"}}>
                            <Button
                                variant="outlined"
                                id="button_open_add_category_item_options_sampling"
                                aria-controls="simple-category-item-options-sampling"
                                aria-haspopup="true"
                                onClick={() => {this.setState({isOpenMenuAddCategoriesForFilter: true})}}
                                style={{width: "100%"}}
                            >
                                Добавить категорию
                            </Button>
                            <Menu
                                id="simple-category-item-options-sampling"
                                anchorEl={document.getElementById("button_open_add_category_item_options_sampling")}
                                keepMounted
                                open={isOpenMenuAddCategoriesForFilter}
                                onClose={() => {this.setState({isOpenMenuAddCategoriesForFilter: false})}}
                            >
                                {categories.map(category => {
                                    if (categoriesIds.find(findIdCategory => findIdCategory === category.id)) {
                                        return null;
                                    }
                                    return (
                                        <MenuItem
                                            key={`KEY_SELECT_id_select_category_edit_item_CATEGORY_${category.id}`}
                                            onClick={() => {
                                                MetricsStore.actionOnTheSite("product_filter.add_category");
                                                this.setState({
                                                    categoriesIds: [category.id, ...categoriesIds],
                                                    isOpenMenuAddCategoriesForFilter: false
                                                });
                                            }}
                                        >
                                            {category.name}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </div>
                        <div style={{display: "flex", flexWrap: "wrap"}}>
                            {categoriesIds.map(categoryID => (
                                <span
                                    key={`KEY_OPTIONS_SAMPLING_ITEM_CATEGORY_ID_${categoryID}`}
                                    className="product_category_container alternate_delete_product_category_container"
                                    title="удалить"
                                    onClick={() => {
                                        MetricsStore.actionOnTheSite("product_filter.delete_category");
                                        this.setState({categoriesIds: categoriesIds.filter(findCategoryID => findCategoryID !== categoryID)});
                                    }}
                                >
                                    {categories.find(category => category.id === categoryID)?.name}
                                </span>
                            ))}
                        </div>
                    </div>
                </Card>
                <div className="container_pagination_and_cards_product">
                    <Pagination
                        page={currentPage}
                        count={countPaginationPages}
                        onChange={(event: object, newPage: number) => {
                            MetricsStore.actionOnTheSite("product_pagination.change_page");
                            this.setState({currentPage: newPage});
                        }}
                    />
                    <div className="container_cards_product">
                        {this.renderProductsWithPagination(keysProducts, countKeysProducts)}
                    </div>
                    <Pagination
                        page={currentPage}
                        count={countPaginationPages}
                        onChange={(event: object, newPage: number) => {
                            MetricsStore.actionOnTheSite("product_pagination.change_page");
                            this.setState({currentPage: newPage});
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default observer(ProductsComponent);
