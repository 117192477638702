import {sendHTTPRequest} from "../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../Config";
import {I_RESPONSE_MODEL_PRODUCTS} from "../../interfaces/ProductInterfaces";

export function getAllProducts(): Promise<I_RESPONSE_MODEL_PRODUCTS>
{
    return new Promise<I_RESPONSE_MODEL_PRODUCTS>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/products_for_users`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}
