import React from 'react';
import { useState } from 'react';
import {
    Card,
    TextField,
    IconButton,
    MenuItem, Button, Menu,
} from "@material-ui/core";
import {
    AddCircleOutline,
    DeleteOutline,
    EditOutlined,
    ClearOutlined,
} from "@material-ui/icons";

import {I_PROPS_ITEM_MODAL} from "../../../interfaces/management/ItemsInterfaces";
import "../../../styles/products/ProductCard.scss";

export default function ItemModalEdit(props: I_PROPS_ITEM_MODAL) {
    const [picture, setPicture] = useState<any>(null);
    const [name, setName] = useState<string>(props.item ? props.item.item.name : "");
    const [composition, setComposition] = useState<string>(props.item ? props.item.item.composition : "");
    const [manufacturer, setManufacturer] = useState<string>(props.item ? props.item.item.manufacturer : "");
    const [description, setDescription] = useState<string>(props.item ? props.item.item.description : "");
    const [productUnit, setProductUnit] = useState<string>(props.item ? props.item.item.product_unit : "");
    const [categoriesIds, setCategoriesIds] = useState<Array<number>>(props.item?.categories ? props.item.categories : []);
    const [isOpenMenuAddCategory, setIsOpenMenuAddCategory] = useState<boolean>(false);

    const addCategory = (inIdCategory: number) => {
        let indexFind = categoriesIds.find(categoryID => categoryID === inIdCategory);
        if (!indexFind) {
            setCategoriesIds([inIdCategory, ...categoriesIds]);
        }
    };

    return (
        <div className="base_edit_content_container">
            {props.item ?
                <h4>
                    Редактирование продукта
                </h4>
                :
                <h4>
                    Создание продукта
                </h4>
            }
            <input type="file" accept="image/png, image/jpeg" onChange={(event) => {if (event.target.files) setPicture(event.target.files[0]);}}/>
            <TextField
                label="Название"
                variant="outlined"
                value={name}
                className="field"
                onChange={(event) => {setName(event.target.value)}}
            />
            <TextField
                label="Состав"
                variant="outlined"
                value={composition}
                className="field"
                onChange={(event) => {setComposition(event.target.value)}}
            />
            <TextField
                label="Производитель"
                variant="outlined"
                value={manufacturer}
                className="field"
                onChange={(event) => {setManufacturer(event.target.value)}}
            />
            <TextField
                label="Описание"
                variant="outlined"
                value={description}
                className="field"
                onChange={(event) => {setDescription(event.target.value)}}
            />
            <TextField
                label="Измерение"
                variant="outlined"
                value={productUnit}
                className="field"
                onChange={(event) => {setProductUnit(event.target.value)}}
            />
            <div style={{width: '100%', marginTop: '10px'}}>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    {categoriesIds.map(categoryID => (
                        <span key={`KEY_EDIT_MODAL_ITEM_CATEGORY_ID_${categoryID}`} className="product_category_container">
                            {props.categories.find(category => category.id === categoryID)?.name}
                            <IconButton
                                style={{width: "10px", height: "10px", marginLeft: "5px"}}
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setCategoriesIds(categoriesIds.filter(findCategoryID => findCategoryID !== categoryID));
                                }}
                            >
                                <ClearOutlined />
                            </IconButton>
                        </span>
                    ))}
                </div>
                <div>
                    <Button
                        id="button_open_add_category_item_menu"
                        aria-controls="simple-category-item-menu"
                        aria-haspopup="true"
                        onClick={() => {setIsOpenMenuAddCategory(true)}} style={{width: "100%"}}
                    >
                        Добавить категорию
                    </Button>
                    <Menu
                        id="simple-category-item-menu"
                        anchorEl={document.getElementById("button_open_add_category_item_menu")}
                        keepMounted
                        open={isOpenMenuAddCategory}
                        onClose={() => {setIsOpenMenuAddCategory(false)}}
                    >
                        {props.categories.map(category => {
                            if (categoriesIds.find(findIdCategory => findIdCategory === category.id)) {
                                return null;
                            }
                            return (
                                <MenuItem
                                    key={`KEY_SELECT_id_select_category_edit_item_CATEGORY_${category.id}`}
                                    onClick={() => {
                                        addCategory(category.id);
                                        setIsOpenMenuAddCategory(false);
                                    }}
                                >
                                    {category.name}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            </div>
            <div>
                {props.item ?
                    <div>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                if (props.item) {
                                    props.handleChange({
                                        item: {
                                            id: props.item.item.id,
                                            name: name,
                                            composition: composition,
                                            description: description,
                                            manufacturer: manufacturer,
                                            product_unit: productUnit,
                                            picture: picture,
                                        },
                                        categories: categoriesIds
                                    });
                                }
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                if (props.item) {
                                    props.handleDelete(props.item.item.id);
                                }
                            }}
                        >
                            <DeleteOutline />
                        </IconButton>
                    </div>
                    :
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            props.handleCreate({
                                item: {
                                    name: name,
                                    composition: composition,
                                    description: description,
                                    manufacturer: manufacturer,
                                    product_unit: productUnit,
                                    picture: picture,
                                },
                                categories: categoriesIds
                            });
                        }}
                    >
                        <AddCircleOutline />
                    </IconButton>
                }
            </div>
        </div>
    );
}