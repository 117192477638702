import {I_REQUEST, I_RESPONSE} from "../interfaces/RequestInterfaces";
import {getAccessTokenFromLocalStorage} from "./LocalStorageWorker";
import {checkExistTokenAuth} from "../components/auth/api";
import UserStore from "../store/UserStore";

export function sendHTTPRequest(inRequestQuery: I_REQUEST): Promise<I_RESPONSE> {
    let headers: object = {
        "Accept": "application/json"
    };
    if (!inRequestQuery.bodyIsNotJSON) {
        //@ts-ignore
        headers["Content-Type"] = "application/json";
    }
    let Init: RequestInit = {
        method: inRequestQuery.method.toUpperCase(),
        //@ts-ignore
        headers: headers
    };
    if (inRequestQuery.headers) {
        Object.assign(Init.headers, inRequestQuery.headers);
    }
    if (checkExistTokenAuth()) {
        let accessToken: string | null = getAccessTokenFromLocalStorage();
        if (accessToken) {
            Object.assign(Init.headers, {"Authorization": "Bearer " + accessToken});
        }
    }
    if (inRequestQuery.data) {
        if (inRequestQuery.bodyIsNotJSON) {
            //@ts-ignore
            Init.body = inRequestQuery.data;
        } else {
            Init.body = JSON.stringify(inRequestQuery.data);
        }
    }
    return new Promise<I_RESPONSE>((resolve, reject) => {
        fetch(inRequestQuery.url, Init)
            .then(response => {
                let preparedResponse: I_RESPONSE;
                    response.json().then(data => {
                        preparedResponse = {
                            code: response.status,
                            message: response.statusText,
                            data: data
                        };
                        if (response.ok) {
                            resolve(preparedResponse);
                        } else {
                            if (response.status === 422) {
                                let alertMessage = data.message;
                                if (data.errors) {
                                    alertMessage += '\nОшибки:';
                                    for (let key in data.errors) {
                                        alertMessage += `\n${key}:`;
                                        data.errors[key].forEach((error: string) => {
                                            alertMessage += `\n${error}`;
                                        });
                                    }
                                }
                                alert(alertMessage);
                                reject(preparedResponse);
                            } else if (data.message && data.message.length > 0) {
                                let alertMessage = data.message;
                                if (data.errors) {
                                    alertMessage += '\nОшибки:';
                                    data.errors.forEach((error: string) => {
                                        alertMessage += `\n${error}`;
                                    });
                                }
                                alert(alertMessage);
                                reject(preparedResponse);
                            }
                            if (response.status === 401) {
                                UserStore.changeAuthStatus(false);
                                reject(preparedResponse);
                            } else {
                                reject(preparedResponse);
                            }
                        }
                    })
                        .catch(res => {
                            if (response.ok) {
                                resolve({
                                    code: response.status,
                                    message: response.statusText,
                                    data: null
                                });
                            } else {
                                reject(null);
                            }
                        });
            })
            .catch(() => {
                alert("ошибка выполнения запроса");
                reject(null);
            });
    });
}
