import {
    action,
//    computed,
    decorate,
    observable
} from "mobx";
import {I_USER_DTO} from "../interfaces/UserStoreInterfaces";
import {checkExistTokenAuth} from "../components/auth/api";

class UserStore {
    authorizationStatus: boolean = false;
    user: I_USER_DTO | null = null;

    constructor() {
        this.authorizationStatus = checkExistTokenAuth();
    }

    changeAuthStatus(newStatus: boolean) {
        this.authorizationStatus = newStatus;
    }

    changeUserInfo(newUserInfo: I_USER_DTO | null) {
        this.user = newUserInfo;
    }
}

// @ts-ignore
UserStore = decorate(UserStore, {
    authorizationStatus: observable,
    user: observable,
    changeAuthStatus: action,
    changeUserInfo: action,
});

export default new UserStore();