import React from 'react';
import {
    Card,
} from "@material-ui/core";
import {
    EditOutlined,
} from "@material-ui/icons";

import "../../../styles/management/SchedulesComponent.scss";
import {
    I_PROPS_SCHEDULE_CARD
} from "../../../interfaces/management/SchedulesInterfaces";


export default function ScheduleCardComponent(props: I_PROPS_SCHEDULE_CARD) {
    return (
        <Card className="schedule_card_container">
            <span><b>Название:</b> {props.schedule.name}</span>
            <span><b>Понедельник:</b> {props.schedule.monday}</span>
            <span><b>Вторник:</b> {props.schedule.tuesday}</span>
            <span><b>Среда:</b> {props.schedule.wednesday}</span>
            <span><b>Четверг:</b> {props.schedule.thursday}</span>
            <span><b>Пятница:</b> {props.schedule.friday}</span>
            <span><b>Суббота:</b> {props.schedule.saturday}</span>
            <span><b>Воскресенье:</b> {props.schedule.sunday}</span>
            <span><b>Выходные:</b> {props.schedule.holiday}</span>
            <span><b>Особые:</b> {props.schedule.particular}</span>
            {props.onClick &&
                <div
                    className="container_hover_edit"
                    onClick={() => {if (props.onClick) props.onClick(props.schedule)}}
                >
                    <EditOutlined className="edit_icon"/>
                </div>
            }
        </Card>
    );
}