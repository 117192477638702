import React from 'react';
import { useState, useEffect } from 'react';
import {
    Table,
    IconButton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import {
    EditOutlined,
    Clear,
} from "@material-ui/icons";

import {
    changeLocationDocument,
    getAllLocationsDocuments,
} from "./api";
import {
    I_LOCATION_DOCUMENT,
    I_PROPS_LOCATIONS_DOCUMENTS_PROMISE_ALL, I_RESPONSE_LOCATIONS_DOCUMENTS_PROMISE_ALL
} from "../../../interfaces/management/LocationDocumentInterfaces";
import {Promise} from "q";
import {getAllDocuments} from "../documents/api";
import {I_DOCUMENT} from "../../../interfaces/management/DocumentsInterfaces";


export default function LocationsDocumentsComponent() {
    const [locationsDocuments, setLocationsDocuments] = useState<Array<I_LOCATION_DOCUMENT>>([]);
    const [documents, setDocuments] = useState<Array<I_DOCUMENT>>([]);
    const [currentChangeLocationDocument, setCurrentChangeLocationDocument] = useState<I_LOCATION_DOCUMENT | null>(null);

    useEffect(() => {
        //@ts-ignore
        Promise.all<I_PROPS_LOCATIONS_DOCUMENTS_PROMISE_ALL>([
            getAllLocationsDocuments(),
            getAllDocuments()
        ])
            .then((responces: I_RESPONSE_LOCATIONS_DOCUMENTS_PROMISE_ALL) => {
                setLocationsDocuments(responces[0]);
                setDocuments(responces[1]);
            });
    }, []);

    const handleChangeLocationDocument = () => {
        if (currentChangeLocationDocument) {
            changeLocationDocument(currentChangeLocationDocument)
                .then(res => {
                    let copyLocationsDocument: Array<I_LOCATION_DOCUMENT> = locationsDocuments.slice();
                    let indexInLocationsDocuments: number = copyLocationsDocument.findIndex(location => location.id === res.id);
                    if (indexInLocationsDocuments !== -1) {
                        copyLocationsDocument[indexInLocationsDocuments] = res;
                    }
                    setLocationsDocuments(copyLocationsDocument);
                    setCurrentChangeLocationDocument(null);
                });
        }
    };

    const generateSelect = (idSelect: string, inValue: number | null, inOnChange: (inValue: number) => void) => {
        return (
            <FormControl variant="outlined" style={{width: "100%"}}>
                <InputLabel id={`ID_SELECT_LABEL_DOCUMENT_ID_${idSelect}`}>Документ</InputLabel>
                <Select
                    labelId={`ID_SELECT_LABEL_DOCUMENT_ID_${idSelect}`}
                    id={`ID_SELECT_DOCUMENT_ID_${idSelect}`}
                    value={inValue ? `${inValue}` : undefined}
                    onChange={(event) => {
                        let value = event.target.value;
                        if (typeof value === "number") {
                            inOnChange(value);
                        }
                    }}
                >
                    {documents.map(document => (
                        <MenuItem
                            key={`KEY_SELECT_${idSelect}_DOCUMENTS_ITEM_${document.id}`}
                            value={document.id}
                        >
                            {document.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const extractDocumentName = (documentID: number): string => {
        const findDocument: I_DOCUMENT | undefined = documents.find(document => document.id === documentID);
        return findDocument ? findDocument.name : '';
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Документ</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {locationsDocuments.map(location => (
                            <TableRow key={location.id}>
                                <TableCell component="th" scope="row">
                                    <p>
                                        {location.name}
                                    </p>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangeLocationDocument && currentChangeLocationDocument.id === location.id ?
                                        generateSelect(
                                            `LOCATIONS_DOCUMENTS_${location.id}`,
                                            currentChangeLocationDocument.id_d,
                                            (inValue: number) => {
                                                currentChangeLocationDocument.id_d = inValue;
                                                setCurrentChangeLocationDocument({...currentChangeLocationDocument});
                                            })
                                        :
                                        location.id_d !== null &&
                                            <p>{extractDocumentName(location.id_d)}</p>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            if (currentChangeLocationDocument) {
                                                handleChangeLocationDocument();
                                            } else {
                                                setCurrentChangeLocationDocument(location);
                                            }
                                        }}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    {currentChangeLocationDocument && currentChangeLocationDocument.id === location.id &&
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                setCurrentChangeLocationDocument(null);
                                            }}
                                        >
                                            <Clear/>
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}