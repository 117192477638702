import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {I_SITE_CONFIGURATION} from "../../../interfaces/management/SiteConfigurationsInterfaces";

export function getAllSiteConfigurations(): Promise<I_SITE_CONFIGURATION[]>
{
    return new Promise<I_SITE_CONFIGURATION[]>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/configurations/site`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changeSiteConfiguration(newInfo: I_SITE_CONFIGURATION): Promise<I_SITE_CONFIGURATION>
{
    return new Promise<I_SITE_CONFIGURATION>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/configurations/site/${newInfo.id}`,
            data: newInfo,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}