import React from 'react';
import { useState, useEffect } from 'react';
import {
    Table,
    IconButton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
} from "@material-ui/core";
import {
    EditOutlined,
    Clear,
} from "@material-ui/icons";

import {
    changeSiteConfiguration,
    getAllSiteConfigurations
} from "./api";
import {I_SITE_CONFIGURATION} from "../../../interfaces/management/SiteConfigurationsInterfaces";


export default function SiteConfigurationsComponent() {
    const [siteConfigurations, setSiteConfigurations] = useState<Array<I_SITE_CONFIGURATION>>([]);
    const [currentChangeSiteConfiguration, setCurrentChangeSiteConfiguration] = useState<I_SITE_CONFIGURATION | null>(null);

    useEffect(() => {
        getAllSiteConfigurations()
            .then(res => {
                setSiteConfigurations(res);
            });
    }, []);

    const handleChangeSiteConfiguration = () => {
        if (currentChangeSiteConfiguration) {
            changeSiteConfiguration(currentChangeSiteConfiguration)
                .then(res => {
                    let copySiteConfigurations: Array<I_SITE_CONFIGURATION> = siteConfigurations.slice();
                    let indexInSiteConfiguration: number = copySiteConfigurations.findIndex(config => config.id === res.id);
                    if (indexInSiteConfiguration !== -1) {
                        copySiteConfigurations[indexInSiteConfiguration] = res;
                    }
                    setSiteConfigurations(copySiteConfigurations);
                    setCurrentChangeSiteConfiguration(null);
                });
        }
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Значение</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {siteConfigurations.map(siteConfiguration => (
                            <TableRow key={siteConfiguration.id}>
                                <TableCell component="th" scope="row">
                                    <p>
                                        {siteConfiguration.name}
                                    </p>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangeSiteConfiguration && currentChangeSiteConfiguration.id === siteConfiguration.id ?
                                        <TextField
                                            className="text_fields_full"
                                            label="новое значение"
                                            variant="outlined"
                                            value={currentChangeSiteConfiguration.value}
                                            onChange={(event) => {currentChangeSiteConfiguration.value = event.target.value; setCurrentChangeSiteConfiguration({...currentChangeSiteConfiguration});}}
                                        />
                                        :
                                        <p dangerouslySetInnerHTML={{__html: siteConfiguration.value || ''}}/>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            if (currentChangeSiteConfiguration) {
                                                handleChangeSiteConfiguration();
                                            } else {
                                                setCurrentChangeSiteConfiguration(siteConfiguration);
                                            }
                                        }}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    {currentChangeSiteConfiguration && currentChangeSiteConfiguration.id === siteConfiguration.id &&
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                setCurrentChangeSiteConfiguration(null);
                                            }}
                                        >
                                            <Clear/>
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}