import React from 'react';
import {Link} from "react-router-dom";

import "../../../styles/management/navigations/PagesList.scss";
import {
    I_PROPS_PAGES_LIST_COMPONENT,
} from "../../../interfaces/management/Navigations";

export default function PagesList(props: I_PROPS_PAGES_LIST_COMPONENT) {
    const {
        pages,
        handleCloseDrawer
    } = props;

    return (
        <div className='main_container_pages_list'>
            {pages.map((page, index) => (
                <Link
                    key={`KEY_NAVIGATIONS_PAGES_LIST_PAGE_${index}`}
                    onMouseDown={() => {
                        handleCloseDrawer();
                    }}
                    to={page.pathTo}
                    className='link_button'
                >
                    {page.name}
                </Link>
            ))}
        </div>
    );
}