import React from 'react';
import { useState, useEffect } from 'react';
import {
    Modal,
} from "@material-ui/core";

import {getAllItems} from "../items/api";
import {Promise} from "q";
import ProductCard from "../../products/ProductCard";
import {
    I_ITEM
} from "../../../interfaces/ProductInterfaces";
import BaseModalComponent from "../../BaseModalComponent";
import {
    I_PRODUCT_INFO_DTO, I_PRODUCT_INFO_DTO_D_ID,
    I_PROPS_PROMISE_ALL_GET_PRODUCTS_INFO,
    I_RESPONSE_PROMISE_ALL_GET_PRODUCTS_INFO
} from "../../../interfaces/management/ProductsInfoInterfaces";
import {I_POINT_OF_SALE_DTO} from "../../../interfaces/management/PointsOfSaleInterfaces";
import {getAllPointsOfSale} from "../points_of_sale/api";
import {
    changeProductInfo,
    createProductInfo,
    deleteProductInfo,
    getAllProductsInfo
} from "./api";
import ProductsInfoModalEdit from "./ProductsInfoModalEdit";

export default function ProductsInfoComponent() {
    const [items, setItems] = useState<Array<I_ITEM>>([]);
    const [pointsOfSale, setPointsOfSale] = useState<Array<I_POINT_OF_SALE_DTO>>([]);
    const [productsInfo, setProductsInfo] = useState<Array<I_PRODUCT_INFO_DTO>>([]);
    const [selectedItem, setSelectedItem] = useState<I_ITEM | null>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    useEffect(() => {
        //@ts-ignore
        Promise.all<I_PROPS_PROMISE_ALL_GET_PRODUCTS_INFO>([
            getAllItems(),
            getAllPointsOfSale(),
            getAllProductsInfo()
        ])
            .then((responces: I_RESPONSE_PROMISE_ALL_GET_PRODUCTS_INFO) => {
                setItems(responces[0]);
                setPointsOfSale(responces[1]);
                setProductsInfo(responces[2]);
            });
    }, []);

    const handleCreateProductInfo = (inProductInfo: I_PRODUCT_INFO_DTO_D_ID): Promise<void> => {
        return Promise<void>((resolve, reject) => {
            createProductInfo(inProductInfo)
                .then(res => {
                    setProductsInfo([res, ...productsInfo]);
                    resolve();
                })
                .catch((res) => reject());
        });
    };

    const handleChangeProductInfo = (inProductInfo: I_PRODUCT_INFO_DTO): Promise<void> => {
        return Promise<void>((resolve, reject) => {
            changeProductInfo(inProductInfo)
                .then(res => {
                    let copyProductsInfo: Array<I_PRODUCT_INFO_DTO> = productsInfo.slice();
                    let indexInProductInfo: number = copyProductsInfo.findIndex(productInfo => productInfo.id === res.id);
                    if (indexInProductInfo !== -1) {
                        copyProductsInfo[indexInProductInfo] = res;
                    }
                    setProductsInfo(copyProductsInfo);
                    resolve();
                })
                .catch(res => reject());
        });
    };

    const handleDeleteProductInfo = (inId: number) => {
        if (window.confirm("Вы действительно хотите удалить информацию о товаре?")) {
            deleteProductInfo(inId)
                .then(res => setProductsInfo(productsInfo.filter(productInfo => productInfo.id !== inId)));
        }
    };

    return (
        <div className="schedules_page_main_container">
            <Modal
                open={isOpenModal}
                onClose={() => {setIsOpenModal(false)}}
                children={
                    <BaseModalComponent
                        handleCloseModal={() => {setIsOpenModal(false);}}
                        component={
                            selectedItem ? (
                                <ProductsInfoModalEdit
                                    currentItem={selectedItem}
                                    pointsOfSale={pointsOfSale}
                                    productsInfo={productsInfo.filter(product => product.id_i === selectedItem.item.id)}
                                    handleCreate={handleCreateProductInfo}
                                    handleChange={handleChangeProductInfo}
                                    handleDelete={handleDeleteProductInfo}
                                />
                            ) : <div></div>
                        }/>
                }
            />
            {items.map((item: I_ITEM) => (
                <ProductCard
                    key={`PRODUCT_INFO_KEY_PRODUCT_CARD_ID${item.item.id}`}
                    item={item}
                    handleOpenModalAboutProduct={(inItem: I_ITEM) => {setSelectedItem(inItem); setIsOpenModal(true);}}
                />
            ))}
        </div>
    );
}