import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import Navigation from "./components/management/navigations/Navigation";
import MainView from "./components/MainView";

function App() {

    return (
    <div className="App">
      <Switch>
        <Route path='/management' children={<Navigation />}/>
        <Route path='/' children={<MainView />}/>
      </Switch>
    </div>
  );
}

export default App;
