import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {
    I_RESPONSE_MODEL_SCHEDULE,
    I_SCHEDULE_DTO,
    I_SCHEDULE_DTO_D_ID
} from "../../../interfaces/management/SchedulesInterfaces";

export function getAllSchedules(): Promise<I_RESPONSE_MODEL_SCHEDULE>
{
    return new Promise<Array<I_SCHEDULE_DTO>>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/schedules`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function createSchedule(schedule: I_SCHEDULE_DTO_D_ID): Promise<I_SCHEDULE_DTO>
{
    return new Promise<I_SCHEDULE_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/schedules`,
            data: schedule,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changeSchedule(schedule: I_SCHEDULE_DTO): Promise<I_SCHEDULE_DTO>
{
    return new Promise<I_SCHEDULE_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/schedules/${schedule.id}`,
            data: schedule,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function deleteSchedule(idSchedule: number): Promise<number>
{
    return new Promise<number>((resolve, reject) => {
        sendHTTPRequest({
            method: "DELETE",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/schedules/${idSchedule}`,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}