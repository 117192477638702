import {
    action,
    decorate,
    observable
} from "mobx";
import {
    I_CATEGORY,
    I_ITEM,
    I_OBJECT_PRODUCTS,
    I_POINT_OF_SALE,
    I_SCHEDULE
} from "../interfaces/ProductInterfaces";
import {getAllProducts} from "../components/products/api";
import {I_SITE_CONFIGURATION} from "../interfaces/management/SiteConfigurationsInterfaces";
import {I_DOCUMENT, I_DOCUMENT_FULL} from "../interfaces/management/DocumentsInterfaces";
import {I_LOCATION_DOCUMENT} from "../interfaces/management/LocationDocumentInterfaces";
import {DOMAIN_URL} from "../Config";

class ProductsStore {
    isLoaded: boolean = false;
    products: I_OBJECT_PRODUCTS = {};
    pointsOfSale: Array<I_POINT_OF_SALE> = [];
    schedules: Array<I_SCHEDULE> = [];
    items: Array<I_ITEM> = [];
    categories: Array<I_CATEGORY> = [];
    site_configurations: Array<I_SITE_CONFIGURATION> = [];
    documents: Array<I_DOCUMENT> = [];
    locations_documents: Array<I_LOCATION_DOCUMENT> = [];

    updateStore() {
        getAllProducts().then(data => {
            this.isLoaded = true;
            this.products = data.products;
            this.pointsOfSale = data.points_of_sale;
            this.schedules = data.schedules;
            this.items = data.items;
            this.categories = data.categories;
            this.site_configurations = data.site_configurations;
            this.documents = data.documents;
            this.locations_documents = data.locations_documents;
        });
    }

    ejectDocumentByLocationKey(locationIdentify: string): I_DOCUMENT_FULL | null {
        const locationDocument = this.locations_documents.find(location => location.identify === locationIdentify);
        if (!locationDocument)
            return null;
        const documentFinder = this.documents.find(document => document.id === locationDocument.id_d);
        if (!documentFinder)
            return null;
        return {
            ...documentFinder,
            url: `${DOMAIN_URL}/${documentFinder.uri}`
        };
    }

    ejectSiteConfiguration(identify: string): string {
        const configuration = this.site_configurations.find(configuration => configuration.identify === identify);
        return configuration ? configuration.value || '' : '';
    }

    productsWithFilter(
        idPOS: number | null,
        isOnlySelectedCategories: boolean,
        idsCategories: Array<number>
    ): I_OBJECT_PRODUCTS {
        let result: I_OBJECT_PRODUCTS = {...this.products};
        if (idsCategories.length > 0) {
            let newResult: I_OBJECT_PRODUCTS = {};
            Object.keys(result).forEach((keyItem: string) => {
                let numberIdItem: number = parseInt(keyItem);
                let item = this.items.find(item => item.item.id === numberIdItem);
                if (item) {
                    if (isOnlySelectedCategories) {
                        if (idsCategories.reduce((isTrue, idCategory) => {
                            if (isTrue) {
                                //@ts-ignore
                                return item.categories.indexOf(idCategory) > -1;
                            }
                            return false;
                        }, true)) {
                            newResult[keyItem] = result[keyItem];
                        }
                    } else {
                        if (item.categories.find(idCategory => idsCategories.indexOf(idCategory) > -1)) {
                            newResult[keyItem] = result[keyItem];
                        }
                    }
                }
            });
            result = newResult;
        }
        if (idPOS !== null) {
            let newResult: I_OBJECT_PRODUCTS = {};
            Object.keys(result).forEach((keyItem: string) => {
                if (result[keyItem].find(productInfo => productInfo.id_pos === idPOS)) {
                    newResult[keyItem] = result[keyItem];
                }
            });
            result = newResult;
        }
        return result;
    }
}

// @ts-ignore
ProductsStore = decorate(ProductsStore, {
    isLoaded: observable,
    products: observable,
    pointsOfSale: observable,
    schedules: observable,
    items: observable,
    categories: observable,
    productsWithFilter: observable,
    site_configurations: observable,
    updateStore: action,
    ejectSiteConfiguration: observable,
    ejectDocumentByLocationKey: observable,
    documents: observable,
    locations_documents: observable,
});

export default new ProductsStore();
