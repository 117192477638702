import React from "react";
import "../styles/TopBar.scss";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import {DOMAIN_API_URL} from "../Config";
import MetricsStore from "../store/MetricsStore";
import {observer} from "mobx-react";
import ProductsStore from "../store/ProductsStore";
import {
    locationDocuments,
    siteConfigurations
} from "../constants/ConfigurationsConstants";

class TopBar extends React.Component
{
    constructor(props: any) {
        super(props);
    }

    render() {
        const documentOnTopBar = ProductsStore.ejectDocumentByLocationKey(locationDocuments.main_top_bar_document);

        return (
            <div className="top_bar_main_container">
                <img src="/images/logo.gif" alt=""/>
                <Link to="/" className="link_container" onClick={() => {
                    MetricsStore.actionOnTheSite("navigation.change_page");
                }}>
                    <Button>Товары</Button>
                </Link>
                {documentOnTopBar &&
                    <a
                        href={documentOnTopBar.url}
                        className="link_container"
                        target="_blank"
                        onClick={() => {
                            MetricsStore.actionOnTheSite("navigation.open_catalog_gift");
                        }}
                    >
                        <Button>{documentOnTopBar.name}</Button>
                    </a>
                }
                <Link to="/about" className="link_container" onClick={() => {
                    MetricsStore.actionOnTheSite("navigation.change_page");
                }}>
                    <Button>О нас</Button>
                </Link>
                <Link to="/contacts" className="link_container" onClick={() => {
                    MetricsStore.actionOnTheSite("navigation.change_page");
                }}>
                    <Button>Контакты</Button>
                </Link>
                <div
                    className="container_advantages"
                    dangerouslySetInnerHTML={{__html: ProductsStore.ejectSiteConfiguration(siteConfigurations.header_last)}}
                >
                    {/*<span>Более 15 лет на рынке!</span>*/}
                    {/*<span>Наш девиз: цена равна качеству!</span>*/}
                    {/*<span>Мы поставляем изделия лучших производителей!</span>*/}
                </div>
            </div>
        );
    }
}

export default observer(TopBar);