import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {I_SITE_CONFIGURATION} from "../../../interfaces/management/SiteConfigurationsInterfaces";
import {I_LOCATION_DOCUMENT} from "../../../interfaces/management/LocationDocumentInterfaces";

export function getAllLocationsDocuments(): Promise<I_LOCATION_DOCUMENT[]>
{
    return new Promise<I_LOCATION_DOCUMENT[]>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/documents/locations`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changeLocationDocument(newInfo: I_LOCATION_DOCUMENT): Promise<I_LOCATION_DOCUMENT>
{
    return new Promise<I_LOCATION_DOCUMENT>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/documents/locations/${newInfo.id}`,
            data: newInfo,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}