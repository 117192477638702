import {
    KEY_LOCAL_STORAGE_AUTHORIZATION_ACCESS_TOKEN
} from "../constants/LocalStorageConstants";

export function deleteUserAccessTokenForLocalStorage(): void {
    localStorage.removeItem(KEY_LOCAL_STORAGE_AUTHORIZATION_ACCESS_TOKEN);
}

export function setUserAccessTokenForLocalStorage(accessToken: string): void {
    localStorage.setItem(KEY_LOCAL_STORAGE_AUTHORIZATION_ACCESS_TOKEN, accessToken);
}

export function getAccessTokenFromLocalStorage(): string | null {
    return localStorage.getItem(KEY_LOCAL_STORAGE_AUTHORIZATION_ACCESS_TOKEN);
}
