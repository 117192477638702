import React from 'react';
import { useState } from 'react';
import {
    Table,
    IconButton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import {
    DeleteOutline,
    EditOutlined,
    AddCircleOutline,
    Clear,
} from "@material-ui/icons";

import {
    I_RPOPS_MODAL_EDIT_PRODUCTS_INFO
} from "../../../interfaces/management/ProductsInfoInterfaces";


export default function ProductsInfoModalEdit(props: I_RPOPS_MODAL_EDIT_PRODUCTS_INFO) {
    const [currentChangeProductInfoId, setCurrentChangeProductInfoId] = useState<number | null>(null);
    const [selectedPointOfSale, setSelectedPointOfSale] = useState<number | null>(null);
    const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
    const [selectedCount, setSelectedCount] = useState<number | null>(null);
    const [newSelectedPointOfSale, setNewSelectedPointOfSale] = useState<number | null>(null);
    const [newSelectedPrice, setNewSelectedPrice] = useState<number | null>(null);
    const [newSelectedCount, setNewSelectedCount] = useState<number | null>(null);

    const generateSelect = (idSelect: string, inValue: number | null, inOnChange: (inValue: number) => void) => {
        return (
            <FormControl variant="outlined" style={{width: "100%", minWidth: "200px"}}>
                <InputLabel id={`ID_SELECT_LABEL_SCHEDULE_ID_${idSelect}`}>Расписание точки</InputLabel>
                <Select
                    labelId={`ID_SELECT_LABEL_SCHEDULE_ID_${idSelect}`}
                    id={`ID_SELECT_SCHEDULE_ID_${idSelect}`}
                    value={inValue ? `${inValue}` : undefined}
                    onChange={(event) => {
                        let value = event.target.value;
                        if (typeof value === "number") {
                            inOnChange(value);
                        }
                    }}
                >
                    {props.pointsOfSale.map(pointOfSale => (
                        <MenuItem
                            key={`KEY_SELECT_${idSelect}_POS_ITEM_${pointOfSale.id}`}
                            value={pointOfSale.id}
                        >
                            {pointOfSale.address}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const handleCreateProductInfo = () => {
        if (newSelectedPointOfSale !== null && newSelectedPrice !== null && newSelectedCount !== null) {
            props.handleCreate({
                id_i: props.currentItem.item.id,
                id_pos: newSelectedPointOfSale,
                count: newSelectedCount,
                price: newSelectedPrice
            })
                .then(() => {
                    setNewSelectedPointOfSale(null);
                    setNewSelectedPrice(null);
                    setNewSelectedCount(null);
                });
        }
    };

    const handleChangeProductInfo = () => {
        if (currentChangeProductInfoId !== null && selectedPointOfSale !== null && selectedPrice !== null && selectedCount !== null) {
            props.handleChange({
                id: currentChangeProductInfoId,
                id_i: props.currentItem.item.id,
                id_pos: selectedPointOfSale,
                count: selectedCount,
                price: selectedPrice
            })
                .then(() => {
                    setCurrentChangeProductInfoId(null);
                    setSelectedPointOfSale(null);
                    setSelectedPrice(null);
                    setSelectedCount(null);
                });
        }
    };

    const handleDeleteProductInfo = (inId: number) => {
        props.handleDelete(inId);
    };

    return (
        <div style={{overflowY: "auto"}}>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Точка продажи</TableCell>
                            <TableCell>Цена</TableCell>
                            <TableCell>Количество</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {generateSelect(
                                    "NewProductInfo",
                                    newSelectedPointOfSale,
                                    (inValue: number) => {
                                        setNewSelectedPointOfSale(inValue);
                                    }
                                )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField
                                    label="Цена"
                                    variant="outlined"
                                    type="number"
                                    value={""+newSelectedPrice}
                                    onChange={(event) => {setNewSelectedPrice(parseInt(event.target.value))}}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField
                                    label="Количество"
                                    variant="outlined"
                                    type="number"
                                    value={""+newSelectedCount}
                                    onChange={(event) => {setNewSelectedCount(parseInt(event.target.value))}}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCreateProductInfo}
                                >
                                    <AddCircleOutline />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        {props.productsInfo.map(productInfo => (
                            <TableRow key={productInfo.id}>
                                <TableCell component="th" scope="row">
                                    {currentChangeProductInfoId === productInfo.id ?
                                        generateSelect(
                                            `POS_${productInfo.id}`,
                                            selectedPointOfSale,
                                            (inValue: number) => {
                                                setSelectedPointOfSale(inValue)
                                            })
                                        :
                                        <span>
                                            {props.pointsOfSale.find(pos => pos.id === productInfo.id_pos)?.address}
                                        </span>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangeProductInfoId === productInfo.id ?
                                        <TextField
                                            label="Новая цена"
                                            variant="outlined"
                                            type="number"
                                            value={selectedPrice}
                                            onChange={(event) => {setSelectedPrice(parseInt(event.target.value))}}
                                        />
                                        :
                                        <p>
                                            {productInfo.price}
                                        </p>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangeProductInfoId === productInfo.id ?
                                        <TextField
                                            label="Новое количество"
                                            variant="outlined"
                                            type="number"
                                            value={selectedCount}
                                            onChange={(event) => {setSelectedCount(parseInt(event.target.value))}}
                                        />
                                        :
                                        <p>
                                            {productInfo.count}
                                        </p>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            if (currentChangeProductInfoId) {
                                                handleChangeProductInfo();
                                            } else {
                                                setCurrentChangeProductInfoId(productInfo.id);
                                                setSelectedPointOfSale(productInfo.id_pos);
                                                setSelectedPrice(productInfo.price);
                                                setSelectedCount(productInfo.count);
                                            }
                                        }}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    {currentChangeProductInfoId === productInfo.id ?
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                setCurrentChangeProductInfoId(null);
                                            }}
                                        >
                                            <Clear/>
                                        </IconButton>
                                        :
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                handleDeleteProductInfo(productInfo.id);
                                            }}
                                        >
                                            <DeleteOutline/>
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}