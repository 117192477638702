import {
    action,
    decorate,
    observable
} from "mobx";

class MetricsStore {
    countActionsOnSite: number = 0;

    actionOnTheSite(action: string) {
        this.countActionsOnSite++;
        switch (this.countActionsOnSite) {
            //@ts-ignore
            case 5: ym(70678579,'reachGoal','5actionsOnSite'); break;
            //@ts-ignore
            case 10: ym(70678579,'reachGoal','10actionsOnSite'); break;
            //@ts-ignore
            case 30: ym(70678579,'reachGoal','30actionsOnSite'); break;
        }
    }
}

// @ts-ignore
MetricsStore = decorate(MetricsStore, {
    countActionsOnSite: observable,
    actionOnTheSite: action,
});

export default new MetricsStore();