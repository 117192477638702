import React, {ReactElement, useEffect, useState} from "react";
import "../styles/Contacts.scss";
import ProductsStore from "../store/ProductsStore";
import {Card, IconButton, Modal} from "@material-ui/core";
import {observer} from "mobx-react";
import BaseModalComponent from "./BaseModalComponent";
import {MapOutlined} from "@material-ui/icons";

function Contacts() {
    const [modalContent, setModalContent] = useState<ReactElement | null>(null);

    useEffect(() => {
        if (!ProductsStore.isLoaded) {
            ProductsStore.updateStore();
        }
    }, []);

    const {
        pointsOfSale,
        schedules
    } = ProductsStore;

    const handlePreviewMap = (integratedMap: string) => {
        setModalContent(<div dangerouslySetInnerHTML={{__html:integratedMap}} />);
    };

    return (
        <div className="contacts_main_container">
            <Modal
                open={!!modalContent}
                onClose={() => {setModalContent(null)}}
                children={
                    <BaseModalComponent
                        handleCloseModal={() => {
                            setModalContent(null)
                        }}
                        component={
                            <div>
                                {modalContent}
                            </div>
                        }
                    />
                }
            />
            {pointsOfSale.map((pointOfSale) => {
                const scheduleForPOS = schedules.find(schedule => schedule.id === pointOfSale.id_s);
                return (
                    <Card
                        className="card"
                        key={`KEY_SCHEDULE_POS_FOR_USER_ID_${pointOfSale.id}`}
                    >
                        <table>
                            <tr>
                                <td>Адрес:</td>
                                <td><b>{pointOfSale.address}</b></td>
                            </tr>
                            <tr>
                                <td>Телефон:</td>
                                <td><a href={`tel:+${ pointOfSale.phone }`}><b>+{pointOfSale.phone}</b></a></td>
                            </tr>
                            {pointOfSale.map_integration &&
                                <tr>
                                    <td>Карта:</td>
                                    <td>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                handlePreviewMap(pointOfSale.map_integration);
                                            }}
                                        >
                                            <MapOutlined/>
                                        </IconButton>
                                    </td>
                                </tr>
                            }
                            <details>
                                <summary>Расписание работы</summary>
                                {scheduleForPOS &&
                                    <div>
                                        <tr>
                                            <td>Понедельник:</td>
                                            <td><b>{scheduleForPOS.monday}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Вторник:</td>
                                            <td><b>{scheduleForPOS.tuesday}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Среда:</td>
                                            <td><b>{scheduleForPOS.wednesday}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Четверг:</td>
                                            <td><b>{scheduleForPOS.thursday}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Пятница:</td>
                                            <td><b>{scheduleForPOS.friday}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Суббота:</td>
                                            <td><b>{scheduleForPOS.saturday}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Воскресенье:</td>
                                            <td><b>{scheduleForPOS.sunday}</b></td>
                                        </tr>
                                        {(scheduleForPOS.holiday && scheduleForPOS.holiday.length > 2) &&
                                            <tr>
                                                <td>Праздники:</td>
                                                <td><b>{scheduleForPOS.holiday}</b></td>
                                            </tr>
                                        }
                                        {(scheduleForPOS.particular && scheduleForPOS.particular.length > 2) &&
                                            <tr>
                                                <td>Другое:</td>
                                                <td><b>{scheduleForPOS.particular}</b></td>
                                            </tr>
                                        }
                                    </div>
                                }
                            </details>
                        </table>
                    </Card>
                );
            })}
        </div>
    );
}

export default observer(Contacts);