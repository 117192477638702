import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {I_PRODUCT_INFO_DTO, I_PRODUCT_INFO_DTO_D_ID} from "../../../interfaces/management/ProductsInfoInterfaces";

export function getAllProductsInfo(): Promise<Array<I_PRODUCT_INFO_DTO>>
{
    return new Promise<Array<I_PRODUCT_INFO_DTO>>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/products/products_information`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function createProductInfo(productInfo: I_PRODUCT_INFO_DTO_D_ID): Promise<I_PRODUCT_INFO_DTO>
{
    return new Promise<I_PRODUCT_INFO_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/products/products_information`,
            data: productInfo,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changeProductInfo(productInfo: I_PRODUCT_INFO_DTO): Promise<I_PRODUCT_INFO_DTO>
{
    return new Promise<I_PRODUCT_INFO_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/products/products_information/${productInfo.id}`,
            data: productInfo,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function deleteProductInfo(idProductInfo: number): Promise<number>
{
    return new Promise<number>((resolve, reject) => {
        sendHTTPRequest({
            method: "DELETE",
            url: `${DOMAIN_API_URL}/api/management/products/products_information/${idProductInfo}`,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}