export type PropsWeekDay = {
    day: string,
    dayRU: string,
};


export function getWeekDay(date?: any) {
    date = date || new Date();
    const days: PropsWeekDay[] = [
        {
            day: 'sunday',
            dayRU: 'Воскресенье',
        },
        {
            day: 'monday',
            dayRU: 'Понедельник',
        },
        {
            day: 'tuesday',
            dayRU: 'Вторник',
        },
        {
            day: 'wednesday',
            dayRU: 'Среда',
        },
        {
            day: 'thursday',
            dayRU: 'Четверг',
        },
        {
            day: 'friday',
            dayRU: 'Пятница',
        },
        {
            day: 'saturday',
            dayRU: 'Суббота',
        }
    ];
    return days[date.getDay()];
}