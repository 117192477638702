import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {I_CATEGORY_ITEM_DTO, I_RESPONSE_MODEL_CATEGORIES_ITEM} from "../../../interfaces/management/CategoriesItemInterfaces";

export function getAllCategoriesItem(): Promise<I_RESPONSE_MODEL_CATEGORIES_ITEM>
{
    return new Promise<I_RESPONSE_MODEL_CATEGORIES_ITEM>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/products/categories_item`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function createCategoryItem(nameCategory: string): Promise<I_CATEGORY_ITEM_DTO>
{
    return new Promise<I_CATEGORY_ITEM_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/products/categories_item`,
            data: {
               name: nameCategory
            },
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changeCategoryItem(idCategory: number, nameCategory: string): Promise<I_CATEGORY_ITEM_DTO>
{
    return new Promise<I_CATEGORY_ITEM_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/products/categories_item/${idCategory}`,
            data: {
                id: idCategory,
                name: nameCategory
            },
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function deleteCategoryItem(idCategory: number): Promise<number>
{
    return new Promise<number>((resolve, reject) => {
        sendHTTPRequest({
            method: "DELETE",
            url: `${DOMAIN_API_URL}/api/management/products/categories_item/${idCategory}`,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}