import React from 'react';
import {
    Card,
    IconButton,
} from "@material-ui/core";
import {
    ClearOutlined,
} from "@material-ui/icons";

import "../styles/BaseModalComponent.scss";
import {I_PROPS_BASE_MODAL_COMPONENT} from "../interfaces/BaseModalInterfaces";

export default function BaseModalComponent(props: I_PROPS_BASE_MODAL_COMPONENT) {
    return (
        <Card className="base_modal_component_container">
            <IconButton
                edge="start"
                color="inherit"
                className="close_button"
                onClick={props.handleCloseModal}
            >
                <ClearOutlined />
            </IconButton>
            {props.component}
        </Card>
    );
}