import React from "react";
import "../styles/BottomBar.scss";
import {observer} from "mobx-react";
import ProductsStore from "../store/ProductsStore";
import {siteConfigurations} from "../constants/ConfigurationsConstants";

class BottomBar extends React.Component
{
    constructor(props: any) {
        super(props);
    }

    render() {
        let currentDate = new Date();

        return (
            <div className="bottom_bar_main_container">
                <div className="main_center_content_container">
                    <span dangerouslySetInnerHTML={{__html: ProductsStore.ejectSiteConfiguration(siteConfigurations.footer_first)}}/>
                    <span dangerouslySetInnerHTML={{__html: ProductsStore.ejectSiteConfiguration(siteConfigurations.footer_second)}}/>
                    <span dangerouslySetInnerHTML={{__html: ProductsStore.ejectSiteConfiguration(siteConfigurations.footer_third)}}/>
                    {/*<span>ИП: <b>Сторчакова И.В.</b></span>*/}
                    {/*<span>тел: <b>8-908-953-78-23</b></span>*/}
                    {/*<span>e-mail: <b>sladost42@gmail.com</b></span>*/}
                </div>
                <div className="copyright_container">
                    <span>Copyright © 2011-{currentDate.getFullYear()}. Сладкая Радость</span>
                </div>
            </div>
        );
    }
}

export default observer(BottomBar);