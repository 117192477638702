import React from 'react';
import { useState, useEffect } from 'react';
import {
    Card,
    Modal,
} from "@material-ui/core";
import {
    AddCircleOutline,
} from "@material-ui/icons";

import "../../../styles/management/SchedulesComponent.scss";
import {
    I_SCHEDULE_DTO, I_SCHEDULE_DTO_D_ID
} from "../../../interfaces/management/SchedulesInterfaces";
import {
    getAllSchedules,
    createSchedule,
    changeSchedule,
    deleteSchedule,
} from "./api";
import ScheduleCardComponent from "./ScheduleCardComponent";
import ScheduleModalEdit from "./ScheduleModalEdit";

export default function SchedulesComponent() {
    const [schedules, setSchedules] = useState<Array<I_SCHEDULE_DTO>>([]);
    const [selectedSchedule, setSelectedSchedule] = useState<I_SCHEDULE_DTO | null>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    useEffect(() => {
        getAllSchedules()
            .then(res => {
                setSchedules(res);
            });
    }, []);

    const handleCreateSchedule = (inSchedule: I_SCHEDULE_DTO_D_ID) => {
        createSchedule(inSchedule)
            .then(res => {
                setSchedules([res, ...schedules]);
                setIsOpenModal(false);
            });
    };

    const handleChangeSchedule = (inSchedule: I_SCHEDULE_DTO) => {
        changeSchedule(inSchedule)
            .then(res => {
                let copySchedules: Array<I_SCHEDULE_DTO> = schedules.slice();
                let indexInSchedules: number = copySchedules.findIndex(schedule => schedule.id === res.id);
                if (indexInSchedules !== -1) {
                    copySchedules[indexInSchedules] = res;
                }
                setSchedules(copySchedules);
                setIsOpenModal(false);
            });
    };

    const handleDeleteSchedule = (inId: number) => {
        if (window.confirm("Вы точно хотите удалить расписание?")) {
            deleteSchedule(inId)
                .then(res => {
                    setSchedules(schedules.filter(schedule => schedule.id !== inId));
                    setIsOpenModal(false);
                });
        }
    };

    return (
        <div className="schedules_page_main_container">
            <Modal
                open={isOpenModal}
                onClose={() => {setIsOpenModal(false)}}
                children={
                    <ScheduleModalEdit
                        schedule={selectedSchedule}
                        handleCloseModal={() => {setIsOpenModal(false)}}
                        handleCreate={handleCreateSchedule}
                        handleChange={handleChangeSchedule}
                        handleDelete={handleDeleteSchedule}
                    />
                }
            />
            <Card
                className="schedule_card_container schedule_card_cursor_pointer"
                onClick={() => {
                    setSelectedSchedule(null);
                    setIsOpenModal(true);
                }}
            >
                <AddCircleOutline className="icon_add"/>
            </Card>
            {schedules.map(schedule => (
                <ScheduleCardComponent
                    schedule={schedule}
                    onClick={(inSchedule: I_SCHEDULE_DTO) => {
                        setSelectedSchedule(inSchedule);
                        setIsOpenModal(true);
                    }}
                />
            ))}
        </div>
    );
}