import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {I_ITEM} from "../../../interfaces/ProductInterfaces";
import {I_PROPS_CHANGE_OR_CREATE_ITEM} from "../../../interfaces/management/ItemsInterfaces";

export function getAllItems(): Promise<Array<I_ITEM>>
{
    return new Promise<Array<I_ITEM>>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/products/items`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

function generatedFormData(item: I_PROPS_CHANGE_OR_CREATE_ITEM) {
    let preparedBodyForRequest = new FormData();
    if (item.item.id) {
        preparedBodyForRequest.append('id', ''+item.item.id);
    }
    if (item.item.picture) {
        preparedBodyForRequest.append('picture', item.item.picture);
    }
    preparedBodyForRequest.append('name', item.item.name);
    preparedBodyForRequest.append('composition', item.item.composition);
    preparedBodyForRequest.append('manufacturer', item.item.manufacturer);
    preparedBodyForRequest.append('description', item.item.description);
    preparedBodyForRequest.append('product_unit', item.item.product_unit);
    preparedBodyForRequest.append('categories_item', JSON.stringify(item.categories));
    return preparedBodyForRequest;
}

export function createItem(item: I_PROPS_CHANGE_OR_CREATE_ITEM): Promise<I_ITEM>
{
    return new Promise<I_ITEM>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/products/items`,
            bodyIsNotJSON: true,
            data: generatedFormData(item),
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changeItem(item: I_PROPS_CHANGE_OR_CREATE_ITEM): Promise<I_ITEM>
{
    return new Promise<I_ITEM>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/products/items/${item.item.id}`,
            bodyIsNotJSON: true,
            data: generatedFormData(item),
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function deleteItem(idItem: number): Promise<number>
{
    return new Promise<number>((resolve, reject) => {
        sendHTTPRequest({
            method: "DELETE",
            url: `${DOMAIN_API_URL}/api/management/products/items/${idItem}`,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}