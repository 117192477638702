import React, {ReactElement, ReactHTMLElement} from 'react';
import { useState, useEffect } from 'react';
import {
    Table,
    IconButton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
} from "@material-ui/core";
import {
    MapOutlined,
    DeleteOutline,
    EditOutlined,
    AddCircleOutline,
    Clear,
} from "@material-ui/icons";

import {
    I_POINT_OF_SALE_DTO,
    I_POINT_OF_SALE_DTO_D_ID,
    I_PROPS_POS_PROMISE_ALL, I_RESPONSE_POS_PROMISE_ALL
} from "../../../interfaces/management/PointsOfSaleInterfaces";
import {I_SCHEDULE_DTO} from "../../../interfaces/management/SchedulesInterfaces";
import {Promise} from "q";
import {
    changePointOfSale,
    createPointOfSale,
    deletePointOfSale,
    getAllPointsOfSale
} from "./api";
import {getAllSchedules} from "../schedules/api";
import BaseModalComponent from "../../BaseModalComponent";
import ScheduleCardComponent from "../schedules/ScheduleCardComponent";


export default function PointsOfSaleComponent() {
    const [pointsOfSale, setPointsOfSale] = useState<Array<I_POINT_OF_SALE_DTO>>([]);
    const [schedules, setSchedules] = useState<Array<I_SCHEDULE_DTO>>([]);
    const [modalContent, setModalContent] = useState<ReactElement | null>(null);
    const [newPointOfSale, setNewPointOfSale] = useState<I_POINT_OF_SALE_DTO_D_ID>({address: "", phone: "", id_s: null, map_integration: ""});
    const [currentChangePointOfSale, setCurrentChangePointOfSale] = useState<I_POINT_OF_SALE_DTO | null>(null);

    useEffect(() => {
        //@ts-ignore
        Promise.all<I_PROPS_POS_PROMISE_ALL>([
            getAllSchedules(),
            getAllPointsOfSale()
        ])
            .then((responces: I_RESPONSE_POS_PROMISE_ALL) => {
                setSchedules(responces[0]);
                setPointsOfSale(responces[1]);
            });
    }, []);

    const handleCreatePointOfSale = () => {
        createPointOfSale(newPointOfSale)
            .then(res => {
                setPointsOfSale([res, ...pointsOfSale]);
            });
    };

    const handleChangePointOfSale = () => {
        if (currentChangePointOfSale) {
            changePointOfSale(currentChangePointOfSale)
                .then(res => {
                    let copyPointOfSale: Array<I_POINT_OF_SALE_DTO> = pointsOfSale.slice();
                    let indexInPointsOfSale: number = copyPointOfSale.findIndex(pos => pos.id === res.id);
                    if (indexInPointsOfSale !== -1) {
                        copyPointOfSale[indexInPointsOfSale] = res;
                    }
                    setPointsOfSale(copyPointOfSale);
                    setCurrentChangePointOfSale(null);
                });
        }
    };

    const handleDeletePointOfSale = (inId: number) => {
        if (window.confirm("Вы точно хотите удалить точку продажи?")) {
            deletePointOfSale(inId)
                .then(res => {
                    setPointsOfSale(pointsOfSale.filter(pos => pos.id !== inId));
                });
        }
    };

    const handlePreviewMap = (integratedMap: string) => {
        setModalContent(<div dangerouslySetInnerHTML={{__html:integratedMap}} />);
    };

    const generateSelect = (idSelect: string, inValue: number | null, inOnChange: (inValue: number) => void) => {
        return (
            <FormControl variant="outlined" style={{width: "100%"}}>
                <InputLabel id={`ID_SELECT_LABEL_SCHEDULE_ID_${idSelect}`}>Расписание точки</InputLabel>
                <Select
                    labelId={`ID_SELECT_LABEL_SCHEDULE_ID_${idSelect}`}
                    id={`ID_SELECT_SCHEDULE_ID_${idSelect}`}
                    value={inValue ? `${inValue}` : undefined}
                    onChange={(event) => {
                        let value = event.target.value;
                        if (typeof value === "number") {
                            inOnChange(value);
                        }
                    }}
                >
                    {schedules.map(schedule => (
                        <MenuItem
                            key={`KEY_SELECT_${idSelect}_SCHEDULE_ITEM_${schedule.id}`}
                            value={schedule.id}
                            onClick={() => {setModalContent(<ScheduleCardComponent schedule={schedule}/>)}}
                        >
                            {schedule.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    return (
        <div>
            <Modal
                open={!!modalContent}
                onClose={() => {setModalContent(null)}}
                children={
                    <BaseModalComponent
                        handleCloseModal={() => {
                            setModalContent(null)
                        }}
                        component={
                            <div>
                                {modalContent}
                            </div>
                        }
                    />
                }
            />
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Адрес</TableCell>
                            <TableCell>Телефон</TableCell>
                            <TableCell>Расписание</TableCell>
                            <TableCell>Интеграция карты</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <TextField
                                    className="text_fields_full"
                                    label="адрес"
                                    variant="outlined"
                                    value={newPointOfSale.address}
                                    onChange={(event) => {newPointOfSale.address = event.target.value; setNewPointOfSale({...newPointOfSale});}}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField
                                    className="text_fields_full"
                                    label="телефон"
                                    variant="outlined"
                                    value={newPointOfSale.phone}
                                    onChange={(event) => {newPointOfSale.phone = event.target.value; setNewPointOfSale({...newPointOfSale});}}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {generateSelect(
                                    "NewPointOfSale",
                                    newPointOfSale.id_s,
                                    (inValue: number) => {
                                        newPointOfSale.id_s = inValue;
                                        setNewPointOfSale({...newPointOfSale});
                                    }
                                )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField
                                    className="text_fields_full"
                                    label="Интеграция карты"
                                    variant="outlined"
                                    value={newPointOfSale.map_integration}
                                    onChange={(event) => {newPointOfSale.map_integration = event.target.value; setNewPointOfSale({...newPointOfSale});}}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCreatePointOfSale}
                                >
                                    <AddCircleOutline />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        {pointsOfSale.map(pointOfSale => (
                            <TableRow key={pointOfSale.id}>
                                <TableCell component="th" scope="row">
                                    {currentChangePointOfSale && currentChangePointOfSale.id === pointOfSale.id ?
                                        <TextField
                                            className="text_fields_full"
                                            label="новый адрес"
                                            variant="outlined"
                                            value={currentChangePointOfSale.address}
                                            onChange={(event) => {currentChangePointOfSale.address = event.target.value; setCurrentChangePointOfSale({...currentChangePointOfSale});}}
                                        />
                                        :
                                        <p>
                                            {pointOfSale.address}
                                        </p>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangePointOfSale && currentChangePointOfSale.id === pointOfSale.id ?
                                        <TextField
                                            className="text_fields_full"
                                            label="новый телефон"
                                            variant="outlined"
                                            value={currentChangePointOfSale.phone}
                                            onChange={(event) => {currentChangePointOfSale.phone = event.target.value; setCurrentChangePointOfSale({...currentChangePointOfSale});}}
                                        />
                                        :
                                        <p>
                                            {pointOfSale.phone}
                                        </p>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangePointOfSale && currentChangePointOfSale.id === pointOfSale.id ?
                                            generateSelect(
                                                `POS_${pointOfSale.id}`,
                                                currentChangePointOfSale.id_s,
                                                (inValue: number) => {
                                                    currentChangePointOfSale.id_s = inValue;
                                                    setCurrentChangePointOfSale({...currentChangePointOfSale});
                                                })
                                            :
                                            <span>
                                                {schedules.find(schedule => schedule.id === pointOfSale.id_s)?.name}
                                            </span>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentChangePointOfSale && currentChangePointOfSale.id === pointOfSale.id ?
                                        <TextField
                                            className="text_fields_full"
                                            label="новая интеграция карты"
                                            variant="outlined"
                                            value={currentChangePointOfSale.map_integration}
                                            onChange={(event) => {currentChangePointOfSale.map_integration = event.target.value; setCurrentChangePointOfSale({...currentChangePointOfSale});}}
                                        />
                                        :
                                        pointOfSale.map_integration &&
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={() => {
                                                    handlePreviewMap(pointOfSale.map_integration);
                                                }}
                                            >
                                                <MapOutlined/>
                                            </IconButton>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            if (currentChangePointOfSale) {
                                                handleChangePointOfSale();
                                            } else {
                                                setCurrentChangePointOfSale(pointOfSale);
                                            }
                                        }}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    {currentChangePointOfSale && currentChangePointOfSale.id === pointOfSale.id ?
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                setCurrentChangePointOfSale(null);
                                            }}
                                        >
                                            <Clear/>
                                        </IconButton>
                                        :
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                handleDeletePointOfSale(pointOfSale.id);
                                            }}
                                        >
                                            <DeleteOutline/>
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}