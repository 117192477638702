import {KEY_LOCAL_STORAGE_AUTHORIZATION_ACCESS_TOKEN} from "../../constants/LocalStorageConstants";
import {sendHTTPRequest} from "../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../Config";
import UserStore from "../../store/UserStore";
import {deleteUserAccessTokenForLocalStorage, setUserAccessTokenForLocalStorage} from "../../services/LocalStorageWorker";

export function checkExistTokenAuth(): boolean {
    return localStorage.getItem(KEY_LOCAL_STORAGE_AUTHORIZATION_ACCESS_TOKEN) !== null;
}

export function handleLogin(login: string, password: string): void {
    sendHTTPRequest({
        method: "POST",
        url: `${DOMAIN_API_URL}/api/authentication/login`,
        data: {
            login: login,
            password: password
        }
    })
        .then((response) => {
            setUserAccessTokenForLocalStorage(response.data.token);
            UserStore.changeUserInfo({
                id: response.data.id,
                fio: response.data.fio,
                login: response.data.login,
                phone: response.data.phone,
                email: response.data.email,
            });
            UserStore.changeAuthStatus(true);
        })
        .catch(response => {
            if (response && response.data.error) {
                alert(response.data.error);
            }
        });
}

export function handleLogout() {
    sendHTTPRequest({
        method: "POST",
        url: `${DOMAIN_API_URL}/api/authentication/logout`,
    })
        .then((response) => {
            deleteUserAccessTokenForLocalStorage();
            UserStore.changeUserInfo(null);
            UserStore.changeAuthStatus(false);
        });
}

export function handleAllLogout() {
    sendHTTPRequest({
        method: "POST",
        url: `${DOMAIN_API_URL}/api/authentication/allLogout`,
    })
        .then((response) => {
            deleteUserAccessTokenForLocalStorage();
            UserStore.changeUserInfo(null);
            UserStore.changeAuthStatus(false);
        });
}