import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    Modal, Menu, MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import {
    I_PROPS_FOR_LINK_PAGE,
    I_STATE_FOR_NAVIGATION_COMPONENT,
} from "../../../interfaces/management/Navigations";
import "../../../styles/management/navigations/Navigation.scss";
import {Route, Switch} from "react-router-dom";
import PagesList from "./PagesList";
import CategoriesItemComponent from "../categories/CategoriesItemComponent";
import SchedulesComponent from "../schedules/SchedulesComponent";
import PointsOfSaleComponent from "../points_of_sale/PointsOfSaleComponent";
import ItemsComponent from "../items/ItemsComponent";
import {observer} from "mobx-react";
import UserStore from "../../../store/UserStore";
import AuthorizationComponent from "../../auth/AuthorizationComponent";
import {handleAllLogout, handleLogout} from "../../auth/api";
import ProductsInfoComponent from "../products_info/ProductsInfoComponents";
import SiteConfigurationsComponent from "../site_configurations/SiteConfigurationsComponent";
import DocumentsComponent from "../documents/DocumentsComponent";
import LocationsDocumentsComponent from "../locations_documents/LocationsDocumentsComponent";

const BASE_PATH = '/management';
const PAGES: Array<I_PROPS_FOR_LINK_PAGE> = [
    {
        name: 'Расписание торговых точек',
        pathTo: `${BASE_PATH}/schedules`,
        component: <SchedulesComponent />,
        grants: ['all']
    },
    {
        name: 'Торговые точки',
        pathTo: `${BASE_PATH}/points_of_sale`,
        component: <PointsOfSaleComponent />,
        grants: ['all']
    },
    {
        name: 'Категории товаров',
        pathTo: `${BASE_PATH}/items/categories`,
        component: <CategoriesItemComponent />,
        grants: ['all']
    },
    {
        name: 'Товары',
        pathTo: `${BASE_PATH}/items`,
        component: <ItemsComponent />,
        grants: ['all']
    },
    {
        name: 'Товары в магазинах',
        pathTo: `${BASE_PATH}/products/info`,
        component: <ProductsInfoComponent />,
        grants: ['all']
    },
    {
        name: 'Конфигурация сайта',
        pathTo: `${BASE_PATH}/configurations`,
        component: <SiteConfigurationsComponent />,
        grants: ['all']
    },
    {
        name: 'Документы',
        pathTo: `${BASE_PATH}/documents`,
        component: <DocumentsComponent />,
        grants: ['all']
    },
    {
        name: 'Расположение документов',
        pathTo: `${BASE_PATH}/documents/locations`,
        component: <LocationsDocumentsComponent />,
        grants: ['all']
    },
];

class Navigation extends React.Component<any, I_STATE_FOR_NAVIGATION_COMPONENT>{

    constructor(props: any) {
        super(props);

        this.state = {
            isOpenDrawer: false,
            isOpenExitMenu: false,
        };
    }

    handleOpenOrCloseDrawer(action: boolean) {
        this.setState({isOpenDrawer: action});
    }

    compareArraysGrants(inGrantsPage: Array<number | string>): boolean {
        return inGrantsPage.find(grant => grant === 'all') !== undefined;
    }

    preparedPages(inPages: Array<I_PROPS_FOR_LINK_PAGE>): Array<I_PROPS_FOR_LINK_PAGE> {
        return inPages.filter(page => page.grants ? this.compareArraysGrants(page.grants) : true);
    }

    render() {
        const {
            isOpenDrawer,
            isOpenExitMenu
        } = this.state;
        const {
            user,
            authorizationStatus
        } = UserStore;
        const PREPARED_PAGES = this.preparedPages(PAGES);
        //const CURRENT_PATH = window.location.pathname;
        return (
            <div className="navigations_main_container">
                <Modal
                    open={!authorizationStatus}
                    onClose={() => {}}
                    children={<AuthorizationComponent/>}
                />
                <Drawer
                    anchor="left"
                    open={isOpenDrawer}
                    onClose={() => {this.handleOpenOrCloseDrawer(false)}}
                >
                    <PagesList
                        handleCloseDrawer={() => {this.handleOpenOrCloseDrawer(false)}}
                        pages={PREPARED_PAGES}
                    />
                </Drawer>
                <AppBar className="app_bar" position="static">
                    <Toolbar className="toolbar">
                        <div className="left_tab">
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => {this.handleOpenOrCloseDrawer(true)}}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {PREPARED_PAGES.map((page, index) => (
                                    <Route
                                        key={`KEY_NAVIGATIONS_PAGES_LIST_PAGE_${index}_name`}
                                        exact path={page.pathTo}
                                        children={<span>{page.name}</span>}
                                    />
                                ))}
                            </Typography>
                        </div>
                        <div className="right_tab">
                            <Button
                                style={{color: "white"}}
                                id="button_open_exit_menu"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={() => {this.setState({isOpenExitMenu: true})}}
                            >
                                Выйти
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={document.getElementById("button_open_exit_menu")}
                                keepMounted
                                open={isOpenExitMenu}
                                onClose={() => {this.setState({isOpenExitMenu: false})}}
                            >
                                <MenuItem onClick={() => {this.setState({isOpenExitMenu: false}, handleLogout);}}>Выйти на этом устройстве</MenuItem>
                                <MenuItem onClick={() => {this.setState({isOpenExitMenu: false}, handleAllLogout);}}>Выйти на всех устройствах</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="content_pages_container">
                    <Switch>
                        {PREPARED_PAGES.map((page, index) => (
                            <Route
                                key={`KEY_NAVIGATIONS_PAGES_LIST_PAGE_${index}`}
                                exact path={page.pathTo}
                                children={page.component}
                            />
                        ))}
                    </Switch>
                </div>
            </div>
        );
    }
}

export default observer(Navigation);