import React from 'react';
import { useState, useEffect } from 'react';
import {
    Table,
    IconButton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
} from "@material-ui/core";
import {
    DeleteOutline,
    EditOutlined,
    AddCircleOutline,
    Clear,
} from "@material-ui/icons";

import {
    changeCategoryItem,
    createCategoryItem,
    deleteCategoryItem,
    getAllCategoriesItem
} from "./api";
import {I_CATEGORY_ITEM_DTO} from "../../../interfaces/management/CategoriesItemInterfaces";

export default function CategoriesItemComponent() {
    const [categories, setCategories] = useState<Array<I_CATEGORY_ITEM_DTO>>([]);
    const [newNameCategory, setNewNameCategory] = useState<string>("");
    const [currentChangeCategory, setCurrentChangeCategory] = useState<I_CATEGORY_ITEM_DTO | null>(null);
    useEffect(() => {
        getAllCategoriesItem()
            .then(res => {
                setCategories(res);
            });
    }, []);

    const createCategory = () => {
        createCategoryItem(newNameCategory)
            .then(res => {
                setCategories([res,...categories]);
                setNewNameCategory("");
            });
    };

    const changeCategory = () => {
        if (currentChangeCategory) {
            changeCategoryItem(currentChangeCategory.id, currentChangeCategory.name)
                .then(res => {
                    let copyCategories: Array<I_CATEGORY_ITEM_DTO> = categories.slice();
                    let indexInCategories: number = copyCategories.findIndex(category => category.id === res.id);
                    if (indexInCategories !== -1) {
                        copyCategories[indexInCategories] = res;
                    }
                    setCategories(copyCategories);
                    setCurrentChangeCategory(null);
                });
        }
    };

    const deleteCategory = (inId: number) => {
        if (window.confirm("Вы точно хотите удалить категорию товара?")) {
            deleteCategoryItem(inId)
                .then(res => {
                    setCategories(categories.filter(category => category.id !== inId));
                });
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell>Название категории</TableCell>
                        <TableCell>Действие</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <TextField
                                className="text_fields_full"
                                label="название категории"
                                variant="outlined"
                                value={newNameCategory}
                                onChange={(event) => {setNewNameCategory(event.target.value)}}
                            />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={createCategory}
                            >
                                <AddCircleOutline />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    {categories.map((category: any) => (
                        <TableRow key={category.id}>
                            <TableCell component="th" scope="row">
                                {currentChangeCategory && currentChangeCategory.id === category.id ?
                                    <TextField
                                        className="text_fields_full"
                                        label="Новое название категории"
                                        variant="outlined"
                                        value={currentChangeCategory.name}
                                        onChange={(event) => {setCurrentChangeCategory({id: currentChangeCategory.id, name: event.target.value})}}
                                    />
                                    :
                                    <p>
                                        {category.name}
                                    </p>
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        if (currentChangeCategory) {
                                            changeCategory();
                                        } else {
                                            setCurrentChangeCategory(category);
                                        }
                                    }}
                                >
                                    <EditOutlined />
                                </IconButton>
                                {currentChangeCategory && currentChangeCategory.id === category.id ?
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            setCurrentChangeCategory(null);
                                        }}
                                    >
                                        <Clear/>
                                    </IconButton>
                                    :
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            deleteCategory(category.id);
                                        }}
                                    >
                                        <DeleteOutline/>
                                    </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}