import {sendHTTPRequest} from "../../../services/HTTPWorker";
import {DOMAIN_API_URL} from "../../../Config";
import {
    I_POINT_OF_SALE_DTO,
    I_POINT_OF_SALE_DTO_D_ID,
    I_RESPONSE_MODEL_POINTS_OF_SALE
} from "../../../interfaces/management/PointsOfSaleInterfaces";

export function getAllPointsOfSale(): Promise<I_RESPONSE_MODEL_POINTS_OF_SALE>
{
    return new Promise<I_RESPONSE_MODEL_POINTS_OF_SALE>((resolve, reject) => {
        sendHTTPRequest({
            method: "GET",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/points_of_sale`
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function createPointOfSale(newPOS: I_POINT_OF_SALE_DTO_D_ID): Promise<I_POINT_OF_SALE_DTO>
{
    return new Promise<I_POINT_OF_SALE_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/points_of_sale`,
            data: newPOS,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function changePointOfSale(newInfoPOS: I_POINT_OF_SALE_DTO): Promise<I_POINT_OF_SALE_DTO>
{
    return new Promise<I_POINT_OF_SALE_DTO>((resolve, reject) => {
        sendHTTPRequest({
            method: "POST",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/points_of_sale/${newInfoPOS.id}`,
            data: newInfoPOS,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}

export function deletePointOfSale(idCategory: number): Promise<number>
{
    return new Promise<number>((resolve, reject) => {
        sendHTTPRequest({
            method: "DELETE",
            url: `${DOMAIN_API_URL}/api/management/points_of_sale/points_of_sale/${idCategory}`,
        })
            .then(response => {
                resolve(response.data);
            });
    });
}