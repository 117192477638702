import React, {useState} from "react";
import {
    TextField,
    Button
} from "@material-ui/core";

import "../../styles/AuthorizationComponent.scss";
import {handleLogin} from "./api";


export default function AuthorizationComponent() {
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const decorateHandleLogin = () => {
        handleLogin(login, password);
        setLogin("");
        setPassword("");
    };

    return (
        <div className="authorization_main_container">
            <div className="auth_inner_container">
                <h2>Авторизация</h2>
                <TextField
                    label="Логин"
                    variant="outlined"
                    value={login}
                    className="one_element"
                    onChange={(event) => {setLogin(event.target.value)}}
                />
                <TextField
                    label="Пароль"
                    type="password"
                    variant="outlined"
                    value={password}
                    className="one_element"
                    onChange={(event) => {setPassword(event.target.value)}}
                />
                <Button variant="contained" color="primary" className="one_element" onClick={decorateHandleLogin}>
                    Войти
                </Button>
                {/*<Button variant="contained" color="secondary">*/}
                {/*    Зарегистрироваться*/}
                {/*</Button>*/}
            </div>
        </div>
    );
}